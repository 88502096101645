<template>
<div v-if="perms.includes('Facturation Clients') || perms.includes('Admin')">
   <div v-if="loading">
        <div id="loading-bg">
        <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
   <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="9"
      >
      
        <b-form @submit.prevent id="formdata">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <!-- <logo /> -->
                    <h3 class="text-primary invoice-logo">
                      Devis Client
                    </h3>
                  </div>
                  <!-- <b-card-text class="mb-25">
                    AGADIR 
                  </b-card-text>
                  <b-card-text class="mb-25">
                    Adresse
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +212 0645743797
                  </b-card-text> -->
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <!-- <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Facture N°
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="NumFact"                        
                      />
                    </b-input-group>
                  </div> -->
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Date:
                    </h4>
                      
                    <!-- <flat-pickr
                    format="MM/DD/yyyy"
                      v-model="DateDevis"
                      class="form-control invoice-edit-input"
                    /> -->
                     <b-form-input
                        v-model="DateDevis"
                        type="date"
                        
                      />
                  </div>
                  <!-- <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dueDate"
                      class="form-control invoice-edit-input"
                    />
                  </div> -->
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <!-- <hr class="invoice-spacing"> -->
         
            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="12"
                  class="mb-lg-1"
                >
                  <h6 class="mb-3">
                    Choisir le client:
                  </h6>

                  <!-- Select Client -->
                 <!-- {{invoiceData.client}} -->
                  <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
                    v-model="invoiceData.client"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clients"
                    label="NomSociete"
                    input-id="invoice-data-client"
                    :clearable="false"
                    placeholder="Choisir le client"
                    @input="clientID=invoiceData.client.id"
                  >
                
                    <template #list-header>
                        <!-- v-b-toggle.sidebar-invoice-add-new-customer -->
                      <li
                        @click="addClient()"
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Ajouter nouveau client</span>
                      </li>
                    </template>
                  </v-select>

                  <!-- Selected Client -->
                  <!-- <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.client.NomSociete }}
                    </h6>
                    <b-card-text class="mb-25">
                       {{ invoiceData.client.NumClient }}
                    </b-card-text>
                  </div> -->
                </b-col>

              
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <!-- {{ FactureData.produits}} -->
            <!-- {{PrixProd.Prices}} -->
                <b-card-body class="invoice-padding form-item-section" style="padding-left: 2rem;padding-right: 2rem;">
                  <b-row>
                    <b-col cols="12">
                  <div
                    ref="form"
                    class="repeater-form"
                    
                    
                  >
                    <!-- style="height:220px" :style="{height: trHeight}" -->
                    <b-row
                      v-for="(item, index) in FactureData.produits"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col cols="12">

                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="3"
                            >
                              Produit
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Prix Vente
                            </b-col>
                            <b-col
                              cols="12"
                              lg="1"
                            >
                              TVA
                            </b-col>
                            
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Quantité
                            </b-col>
                             <!-- <template v-if="error">
                                 <small class="text-danger">{{ errorQnty }}</small>
                              </template> -->
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Total H.T
                            </b-col>
                             <b-col
                              cols="12"
                              lg="2"
                            >
                              Total T.T.C
                            </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>
                      <!-- {{clientID}} / {{prod.id}} -->
                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2" style="padding: 0.7rem !important;">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="3"
                            >

                              <label class="d-inline d-lg-none">Produit</label>
                              <!--  v-model="item.Nom" -->
                              <!-- style="border: 1px solid #0000002b;  border-radius: 0.357rem;" -->
                              <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="Products"
                                label="Nom"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                placeholder="Produits" 
                                :disabled="clientID==''"
                                @input="val => updateItemForm(index, val,clientID)"
                                @change="get()"
                              />
                              <!-- [getPrice(clientID,prod.id), -->
                   <!-- <input type="text" class="hidden" ref="prod" @input="getPrice(clientID,prod.id)" > -->

                              <!-- @click="getPrice(invoiceData.client.id,prod.id)" -->
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Prix Vente</label>
                              <!--  {{price}} -->
                              <b-form-input
                                v-model="item.Prix_Vente"
                                type="number"
                                class="mb-2"
                                :name="item.Nom"
                                @input="CalcTTC(FactureData.produits)"
                               
                              />
                               <template v-if="PrixProd.Prices.length>0">
                                <div  v-for="pricePro in PrixProd.Prices" :key="pricePro.id">
                                  <div v-if="Object.entries(pricePro).length">
                                    <small class="text-success" v-if="pricePro.id==item.id"> Vendu : <b>{{pricePro.val}} Dhs</b></small>
                                  </div>
                                  <!-- <div v-else>
                                    <small class="text-warning" v-if="item.id"> Prix : {{item.Prix_Vente}} Dhs</small>
                                  </div> -->
                                </div>
                              </template>
                            </b-col>
                            
                            <b-col
                              cols="12"
                              lg="1"
                            >
                              <label class="d-inline d-lg-none">TVA</label>
                              <b-form-input
                                v-model="item.tva"
                                type="number"
                                class="mb-2"
                                disabled
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Quantité</label>
                              <b-form-input
                                v-model="item.qnt"
                                type="number"
                                class="mb-2"
                                :name="'qnty'+index"
                                :class="error=='qnty'+index ? 'is-invalid':null"
                                @change="checkQnty(item.id,item.qnt,'qnty'+index)"
                                @input="CalcTTC(FactureData.produits)"
                              />
                                   <!-- :class="error ==true ? 'is-invalid':null" -->
                              <template v-if="error=='qnty'+index">
                                 <small class="text-danger">Quantité insiffisante</small>
                              </template>
                          
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Total H.T</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                :value="item.Prix_Vente * item.qnt"
                                disabled
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Total T.T.C </label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                :value="TwoDigits((item.Prix_Vente * item.qnt)*(1+(item.tva/100)))"
                                disabled
                              />
                            </b-col>
                           
                          </b-row>
                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                            />
                            <!-- <feather-icon
                              :id="`form-item-settings-icon-${index}`"
                              size="16"
                              icon="SettingsIcon"
                              class="cursor-pointer"
                              @click="addNewItemInItemForm" 
                            /> -->

                            <!-- Setting Item Form -->
                           
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                   
                  </div>
                    </b-col>
                  <!-- <hr/> FactureData.produits.length -->
                  <b-col cols="12">
                  <b-button  style="margin-top: 30px;"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewItemInItemForm" >
                    Ajouter Produit
                  </b-button>
                  </b-col>
                  </b-row>
                </b-card-body>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
            
                <!-- <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1">
                <b-card-body class="pt-0">
                 <span class="font-weight-bold">Total H.T: </span>
               <b-form-input
                    v-model="totalHT"        
                    type="number"
                    class="mb-2"
                              />
                </b-card-body>
                </b-col> -->
                 <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                 <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Remise  : </span>
               <b-form-input
                    v-model="remise"            
                    type="number"
                    placeholder="5%"
                    class="mb-2" /><br/>
                <span class="font-weight-bold">Note  : </span>
                <b-form-textarea
                    v-model="note"            
                    type="number"
                    rows="2"
                    placeholder="Votre Note"
                    class="mb-2" />
                 </b-card-body>
                </b-col>
                 <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper" style="max-width: 18rem;">
                    <!--  {{TwoDigits(THT)}} DHs -->
                     <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total T.H.T:
                      </p>
                      <p class="invoice-total-amount">
                      {{TwoDigits(THT)}} DHs 
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total TVA:
                      </p>
                      <p class="invoice-total-amount">
                      {{TwoDigits(TTV)}}  
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                      {{TTC}} DHs

                      <!-- <template >

                      </template> -->
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Remise: 
                      </p>
                      <p class="invoice-total-amount">
                        {{remise}} %
                      </p>
                    </div>
                   
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total NET A PAYER:
                      </p>
                      <p class="invoice-total-amount">
                       {{TwoDigits(TTC*(1-(remise/100)))}}
                      </p>
                    </div>
                  </div>
                </b-col>

              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-col
        cols="12"
        md="12"
        xl="12"
        class="invoice-actions mt-md-0 mt-2" >

        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="addFacture()"
            
          >
            Ajouter Le Devis
          </b-button>
      
        </b-card>

       
      </b-col>
            <!-- Note -->
            
          </b-card>
        </b-form>
      </b-col>
 
      <!-- Right Col: Card -->
      <!-- <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2" >

        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="addFacture()"
            
          >
            Ajouter La Facture
          </b-button>
      
        </b-card>

       
      </b-col> -->
    </b-row>
  </section>
</div>
 <div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import moment from 'moment';
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, 
  BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
  BMedia, BMediaAside, BMediaBody, BLink,BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import NotAuthoriz from '../NotAuthoriz.vue'
import axios from 'axios'
// import invoiceStoreModule from './invoiceStoreModule'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BMedia, BMediaAside, BMediaBody, BLink,BImg,
    flatPickr,
    vSelect,
    Logo,NotAuthoriz,
     ValidationProvider,
    ValidationObserver,
    // InvoiceSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data(){
      return{
        clientID:'',
        perms:[],
          loading : false,
          clients:[],
          Products:[],
          NumFact:'',
          totalHT:0,
          totalNET:0,
          tva:0,
          DateDevis:new Date().toISOString().slice(0,10),
          fourniId:0,
          image:null,
          imagepreview:null,
          attachments:[],
          remise:0,
          note:'',
          TTC:0,
          THT:0,
          TTV:0,
          prod:'',
          errorQnty:'',
          error:false,
          UserID:{},


      }
  },
  mounted() {
    this.initTrHeight()
    this.perms=JSON.parse(localStorage.getItem('access'));
    this.$http.get(`${config.API_BASE_URL}/api/auth/getAllClient`).then(res => { this.clients = res.data });
    this.getProduct();
    this.UserID=JSON.parse(localStorage.getItem('userData'))
       
      

  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
    // if(PrixProd.Prices.length>0){
    //   for(let i = 0; i < PrixProd.Prices.length; i++){

    //   }
    // }
    
   
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    


    // RemoveProd(val){
    //      console.log('he')
    // for(var i =0; i< array2.length; i++ ) {
    //   for(var j= 0; j< array1.length;j++) {
    //     if (array2[i].$id === array1[j].$id) {
    //       array1.splice(j,1);
    //       break;
    //     }
    //   }}
    // },
     loadCategories(clientID,id){
        this.$http.get(`${config.API_BASE_URL}/api/auth/GetPrice/${clientID}/${id}`)
      .then(res => { 
        // console.log(res.data)
        })
      },
      TwoDigits(val){
            return parseFloat(val).toFixed(2);
      },
    checkQnty(id,qnt,text){
      // console.log(id+'/'+qnt+'/'+text)
       this.$http.get(`${config.API_BASE_URL}/api/auth/CheckQnty/${id}/${qnt}`)
            .then(res => {
                  if(res.data.message=='Qnty Insufissante'){
                     this.error=text
                  }else if(res.data.message=='success'){
                    this.error=''
                  }
            })
    },
      CalcTTC(item){
        // console.log(item)
        this.TTC=0;
        this.THT=0;
        this.TTV=0;
        for (let i = 0; i < item.length; i++) {
             this.TTC+=parseFloat(this.TwoDigits((item[i].Prix_Vente * item[i].qnt)*(1+(item[i].tva/100))))
              this.THT+=parseFloat((item[i].Prix_Vente*item[i].qnt))
              this.TTV+=parseFloat((item[i].Prix_Vente*item[i].qnt)*(item[i].tva/100))
            }
        //   this.TTC=this.TwoDigits((item.Prix_Vente * item.qnt)*(1+(item.tva/100)))
          // console.log(this.TTC)
      },
       imageSelected(e){
            let selectedFiles=e.target.files;
                if(!selectedFiles.length){
                    return false;
                }
                for(let i=0;i<selectedFiles.length;i++){
                    this.attachments.push(selectedFiles[i]);
                }
                // console.log(this.attachments);
         },
      addClient(){
          this.$router.push('/clients');
      },
      AddProd(){
        if(this.error){
          this.$swal.fire({
              icon: 'error',
              title: 'Quantité Insuffisante',
              text: 'Merci de verifier la quantité' })
        }
        else{
          let data = new FormData;
          data.append('UserID', this.UserID.id);
          data.append('products', JSON.stringify(this.FactureData.produits));
          data.append('client_id', this.invoiceData.client.id);
          data.append('remise', this.remise);
          data.append('DateDevis', this.DateDevis);
          data.append('note', this.note);
          // data.append('DateDevis', moment(String(this.DateDevis)).format('DD/MM/YYYY'));
          
          // console.log(Object.fromEntries(data))
          this.$http.post(`${config.API_BASE_URL}/api/auth/AddDevis`,data)
            .then(res => { 
                if(res.data=='success'){
                    this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Votre Devis est bien ajouté',
                  showConfirmButton: false,
                  timer: 1000 })
                //   document.getElementById("formdata").reset();
                this.$router.push('/DevisClient');
                  }else if(res.data.message=='Qnty Insufissante'){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Quantité Insuffisante',
                        text: 'La quantité du produit '+res.data.Prod+' est insuffisante !' })
                
                }else{
                  this.$swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong! Pleaz try again'
              })
                }
                
                })
        }
          
      },
      getProduct(){
         this.loading = true
          this.$http.get(`${config.API_BASE_URL}/api/auth/ListProducts`)
      .then(res => { this.Products = res.data,  this.loading = false })
      },
      addFacture(){
      if(this.FactureData.produits.length==0  || this.DateDevis=='' || this.invoiceData.client.id==''){
         this.$swal.fire({
              icon: 'error',
              title: 'Champs vides',
              text: 'Merci de remplire tous les champs' })
      }
      else if(this.FactureData.produits.length==1){
         for(let i=0;i<this.FactureData.produits.length;i++){
            if(this.FactureData.produits[i].Nom===null &&  this.FactureData.produits[i].id===0){
                  this.$swal.fire({
                  icon: 'error',
                  title: 'Pas de produit',
                  text: 'Merci d\'ajouter des produits avant de passer votre devis !' })
            }else{
              this.AddProd();
            }
        }
      }
      
      else if(this.FactureData.produits.length>0 ){
             this.AddProd();
        }
         
        // }
          //   let data = new FormData;
          // data.append('UserID', this.UserID.id);
          // data.append('products', JSON.stringify(this.FactureData.produits));
          // data.append('client_id', this.invoiceData.client.id);
          // data.append('remise', this.remise);
          // data.append('DateDevis', moment(String(this.DateDevis)).format('DD/MM/YYYY'));
          // // console.log(Object.fromEntries(data))

          // this.$http.post(`${config.API_BASE_URL}/api/auth/AddDevis`,data)
          //   .then(res => { 
          //       if(res.data=='success'){
          //           this.$swal.fire({
          //         position: 'top-end',
          //         icon: 'success',
          //         title: 'Votre Devis est bien ajouté',
          //         showConfirmButton: false,
          //         timer: 1000 })
          //       //   document.getElementById("formdata").reset();
          //       this.$router.push('/DevisClient');
          //         }else if(res.data.message=='Qnty Insufissante'){
          //           this.$swal.fire({
          //               icon: 'error',
          //               title: 'Quantité Insuffisante',
          //               text: 'La quantité du produit '+res.data.Prod+' est insuffisante !' })
                
          //       }else{
          //         this.$swal.fire({
          //             icon: 'error',
          //             title: 'Oops...',
          //             text: 'Something went wrong! Pleaz try again'
          //     })
          //       }
                
          //       })
      // }
         

        
        //   AddFactFR
      },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.FactureData.produits.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
    this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
      
    },
    removeItem(index) {
      this.FactureData.produits.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.TTC=0;
        for (let i = 0; i < this.FactureData.produits.length; i++) {
             this.TTC+=parseFloat(this.TwoDigits((this.FactureData.produits[i].Prix_Vente * this.FactureData.produits[i].qnt)*(1+(this.FactureData.produits[i].tva/100))))
            }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },

  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
    const price=ref({
      id:0,
      val:0
    });
    // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  //  function loadCategories(clientID,id){
  //       $http.get(`${config.API_BASE_URL}/api/auth/GetPrice/${clientID}/${id}`)
  //     .then(res => { console.log(res.data)})
  //     }

    const itemFormBlankItem = {
      Nom: null,
      id:0,
      Prix_Vente: 0,
      qnt: 0,
      tva:0,
      // price:0
    }
    const PrixProd= ref({
        Prices :[],
    })
    const FactureData= ref({
        produits :[JSON.parse(JSON.stringify(itemFormBlankItem))],
    })
    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })

    
     const updateItemForm = (index, val,clientID) => {
   
       const { Prix_Vente, id, Nom ,tva} = val
      //  console.log(clientID+' /'+id)

       const apiClient= axios.get(`${config.API_BASE_URL}/api/auth/GetPrice/${clientID}/${id}`).then(function (response) {
       if(!PrixProd.value.Prices.find(o => o.id === response.data.id && o.val === response.data.val))
        {PrixProd.value.Prices.push(response.data)}
      //  price.value.val= response.data.prix
      //  price.value.id= id
      // console.log()

      FactureData.value.produits[index].Prix_Vente =  Prix_Vente
      FactureData.value.produits[index].id = id
      FactureData.value.produits[index].Nom = Nom
      FactureData.value.produits[index].tva = tva
      // FactureData.value.produits[index].price = price
      })
      
      // FactureData.value.produits[index].Prix_Vente = Prix_Vente
      
      
    //   this.TTC=this.TwoDigits((Prix_Vente * qnt)*(1+(tva/100)))
    //   FactureData.value.produits[index].total = Prix_Achat*quantite

    
    }
   

    return {
      FactureData,
      invoiceData,
    //   clients,
      updateItemForm,
      PrixProd,
      itemFormBlankItem,price
      // apiClient
    }
  },
}
</script>


<style scoped>
.media-aside.align-self-start {
    display: grid;
}
.invoice-add .invoice-total-wrapper[data-v-cc5561c2] {
    width: 100%;
    max-width: 18rem;
}

[dir] .vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    border: 1px solid #0000002b;
    border-radius: 0.357rem;
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
